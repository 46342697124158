import React from 'react'
import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../../data/defaultFormat'

import desktopImg from './assets/images/desktop.png'
import storeImg from './assets/images/store.png'
import cartImg from './assets/images/cart.png'
import step1Img from './assets/images/step_1.png'
import step2Img from './assets/images/step_2.png'
import step3Img from './assets/images/step_3.png'


import './styles.scss'
const PluginsSteps: React.FC = () => {
  return (
    <section className="container mt-layout-4">
      <h2 className="text-primary text-center" ><FormattedMessage id="plugins.steps.title" /></h2>
      <p className="text-primary text-center"><FormattedMessage id="plugins.steps.subtitle" values={defaultFormatValues} /></p>
      <div className="row">
        <div className="col-md-2 ">
          <div className="d-flex justify-content-center">
            <img src={desktopImg} alt="Desktop" />
          </div>
          <div className="d-flex justify-content-center">
            <img src={step1Img} className="p-4" alt="Step 1" />
          </div>
          <p className="text-primary text-center">
            <FormattedMessage id="plugins.steps.step1" />
          </p>
        </div>
        <div className="col-md-3 col-md-3 d-md-flex justify-content-center align-items-center d-none">
          <div className="plugins-line_step"></div>
        </div>
        <div className="col-md-2">
          <div className="d-flex justify-content-center">
            <img src={storeImg} alt="Store" />
          </div>
          <div className="d-flex justify-content-center">
            <img src={step2Img} className="p-4" alt="Step 2" />
          </div>
          <p className="text-primary text-center">
            <FormattedMessage id="plugins.steps.step2" />
          </p>
        </div>
        <div className="col-md-3 d-md-flex justify-content-center align-items-center d-none">
          <div className="plugins-line_step"></div>
        </div>
        <div className="col-md-2">
          <div className="d-flex justify-content-center">
            <img src={cartImg} alt="Cart" />
          </div>
          <div className="d-flex justify-content-center">
            <img src={step3Img} className="p-4" alt="Step 3" />
          </div>
          <p className="text-primary text-center">
            <FormattedMessage id="plugins.steps.step3" />
          </p>
        </div>
      </div>
    </section>
  )
}

export default PluginsSteps