import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../../data/defaultFormat'

import pago from './assets/images/pago.svg'
import responsive from './assets/images/responsive.svg'
import acceso from './assets/images/acceso.svg'
import whyImg from './assets/images/why_img.svg'
import whyImgEn from './assets/images/whyEn.svg'

import './styles.scss'
import LocaleContext from '../../../contexts/LocaleContext'

const PluginsBeneffits: React.FC = () => {
  const localeCode = useContext(LocaleContext);
  return (
    <section className="container py-layout-5">
      <h3 className="text-primary text-center">Beneficios de nuestro plugin</h3>
      <div className="row">
        <div className="col-lg-4">
          <div className="card uwu-card p-4">
            <img src={pago} alt="" className='uwu-card-image'/>
            <p className='text-center mt-6'><FormattedMessage values={defaultFormatValues} id="plugins.beneffits.item1"/></p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card uwu-card p-4">
            <img src={responsive} alt="" className='uwu-card-image'/>
            <p className='text-center mt-6'><FormattedMessage values={defaultFormatValues} id="plugins.beneffits.item2"/></p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card uwu-card p-4">
            <img src={acceso} alt="" className='uwu-card-image'/>
            <p className='text-center mt-6'><FormattedMessage values={defaultFormatValues} id="plugins.beneffits.item3"/></p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="my-layout-5 d-none d-lg-block">
          {
            localeCode === 'es' ? 
              <img src={whyImg} alt="why" />
            :
              <img src={whyImgEn} alt="why" />
          }
        </div>
        <div className="col-12 d-block d-lg-none d-md-none mt-layout-4">
          <h3 className="text-center text-primary"><FormattedMessage id="plugins.beneffits.title" /></h3>
          <ul className="px-5">
            <li><FormattedMessage values={defaultFormatValues} id="plugins.beneffits.item_list1"/></li>
            <li><FormattedMessage values={defaultFormatValues} id="plugins.beneffits.item_list2"/></li>
            <li><FormattedMessage values={defaultFormatValues} id="plugins.beneffits.item_list3"/></li>
          </ul>

        </div>

      </div>
    </section>
  )
}

export default PluginsBeneffits