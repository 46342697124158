import React from 'react'
import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../../data/defaultFormat'
// import CTAButton from '../../CTAButton/CTAButton'
import SalesButton from '../../SalesButton/SalesButton'
import BgHero from './assets/images/cta.inline.svg'
// import './styles.scss'

const PluginsHero = () => {
  return (
    <section className="container">
      <div className="row hero">
        <div className="col-md-6 d-flex flex-column justify-content-center">
          <h1 className="text-primary plugins-hero_title"><FormattedMessage id="plugins.title" /></h1>
          <strong><h2 className="text-primary plugins-hero_title"><FormattedMessage id="plugins.subtitle" /></h2></strong>
          <p className="text-dark-gray-3">
            <FormattedMessage id="plugins.description"  values={defaultFormatValues}  />
          </p>
          <SalesButton big={true} positionTag="bottom">
            <FormattedMessage id="plugins.cta"/>
          </SalesButton>
        </div>
        <div className="col-md-6">
          <BgHero className="plugins-hero_img" />
        </div>
      </div>
    </section>
  )
}

export default PluginsHero