import React from 'react'
import { FormattedMessage } from 'react-intl'

import prestashopImg from './assets/images/prestashop.png'
import shopifyImg from './assets/images/shopify2.png'
import magentoImg from './assets/images/magento.svg'
import woocImg from './assets/images/wooc.png'
import adobeImg from './assets/images/adobe.png'
import vtex from './assets/images/vtex.svg'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import './styles.scss'

interface PluginsSolutionProps {
  locale: string
}

const PluginsSolution: React.FC<PluginsSolutionProps> = ({locale}) => {
  return (
    <section className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="text-primary">
            <FormattedMessage id="plugins.solutions.title" />
          </h2>
          <p className="text-dark-gray-3">
            <FormattedMessage id="plugins.solutions.description" />
          </p>
          <p className="text-dark-gray-3">
            <FormattedMessage id="plugins.solutions.description2" />
          </p>
        </div>
      </div>
      <div className="col-md-12">
        <div className="container">
          <div className="row my-6 justify-content-md-center">
          <div className="plugins-solution_img-container col-md-3 col-12">
                <div className="plugin-card_solution shopify_img">
                  <div className="plugin-card-contain_img">
                    <img src={shopifyImg} className="plugins-solutions_img" alt="Shopify" />
                  </div>
                  <OutboundLink className="py-4"
                    href={locale === 'en' ? 'https://docs.kushki.com/co/en/plugins/shopify/shopify-kushki' : 'https://docs.kushki.com/co/plugins/shopify/shopify-kushki'}
                    target="_blank">
                      <FormattedMessage id="plugins.know_more" />
                  </OutboundLink>
                </div>
          </div>
            <div className="plugins-solution_img-container col-md-3 col-12">
              <div className="plugin-card_solution">
                <div className="plugin-card-contain_img">
                  <img src={prestashopImg} className="plugins-solutions_img" alt="Prestashop" />
                </div>
                <OutboundLink className="py-4"
                  href="https://docs.kushki.com/ec/plugins/prestashop"
                  target="_blank">
                    <FormattedMessage id="plugins.know_more" />
                </OutboundLink>
              </div>    
            </div>
            <div className="plugins-solution_img-container col-md-3 col-12">
              <div className="plugin-card_solution">
                <img src={magentoImg} className="plugins-solutions_img" alt="Magento" />
                <OutboundLink className="py-4"
                  href="https://docs.kushki.com/ec/plugins/magento"
                  target="_blank">
                    <FormattedMessage id="plugins.know_more" />
                </OutboundLink>
              </div>
            </div>
            <div className="plugins-solution_img-container justify-content-center col-md-3 col-12">
              <div className="plugin-card_solution">
                <img src={woocImg} className="plugins-solutions_img" alt="Woocommerce" />
                <OutboundLink className="py-4"
                  href="https://docs.kushki.com/ec/plugins/woocommerce"
                  target="_blank">
                    <FormattedMessage id="plugins.know_more" />
                </OutboundLink>
              </div>
            </div>
            <div className="plugins-solution_img-container justify-content-center my-layout-4 col-md-3 col-12">
              <div className="plugin-card_solution">
                <img src={adobeImg} className="plugins-solutions_img" alt="Adobe" />
                <OutboundLink className="py-4"
                  href="https://docs.kushki.com/co/plugins/adobe-commerce"
                  target="_blank">
                    <FormattedMessage id="plugins.know_more" />
                </OutboundLink>
              </div>
            </div>
            <div className="plugins-solution_img-container justify-content-center my-layout-4 col-md-3 col-12">
              <div className="plugin-card_solution">
                <img src={vtex} className="plugins-solutions_img" alt="VTEX" />
                <OutboundLink className="py-4"
                  href={locale === 'en' ? 'https://docs.kushki.com/co/en/plugins/kushki-integration-vtex/vtex-kushki' : 'https://docs.kushki.com/co/plugins/kushki-integration-vtex/vtex-kushki'}
                  target="_blank">
                    <FormattedMessage id="plugins.know_more" />
                </OutboundLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PluginsSolution