import React from 'react'
import { FormattedMessage } from 'react-intl'

import laptopImg from './assets/images/computer.svg'

import './styles.scss'

const PluginsBanner = () => {
  return (
    <section className="plugins-banner_bg" >
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
            <h2 className="text-white my-6">
              <FormattedMessage id="plugins.banner.title" />
            </h2>
            <p className="text-white my-5">
              <FormattedMessage id="plugins.banner.description" />
            </p>
            <p className="text-white mt-4">
              <FormattedMessage id="plugins.banner.description2" />
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src={laptopImg} className="plugins-banner_img" alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default PluginsBanner