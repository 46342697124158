import React from 'react'
import Cashbanner from '../../components/CashLanding/CashBanner/CashBanner';
import CashLinks from '../../components/CashLanding/CashLinks/CashLinks';
import CashRocket from '../../components/CashLanding/CashRocket/CashRocket';
import Footer from '../../components/Footer/Footer';
import PluginsBanner from '../../components/Plugins/PluginsBanner/PluginsBanner';
import PluginsBeneffits from '../../components/Plugins/PluginsBeneffits/PluginsBeneffits';
import PluginsHero from '../../components/Plugins/PluginsHero/PluginsHero';
import PluginsIdeal from '../../components/Plugins/PluginsIdeal/PluginsIdeal';
import PluginsSolution from '../../components/Plugins/PluginsSolutions/PluginsSolution';
import PluginsSteps from '../../components/Plugins/PluginsSteps/PluginsSteps';
import SEO from '../../components/SEO/SEO'
import { LocaleTypes } from '../../data/locales';
import IndexLayout from '../../layouts'

interface PluginsProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const plugins: React.FC<PluginsProps> = ({ pathContext, location }) => {
  const content = () => (
    <>
      <SEO titleId="plugins.metaTitle" descriptionId="plugins.metaDescription" />
      <PluginsHero />
      <PluginsSolution locale={pathContext.localeCode} />
      <PluginsBanner />
      <PluginsIdeal />
      <PluginsBeneffits />
      <PluginsSteps />
      <Cashbanner />
      <CashLinks />
      <CashRocket />
      <Footer theme="primary" />
    </>
  )
  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default plugins