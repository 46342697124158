import React from 'react'
import { FormattedMessage } from 'react-intl'

import CableIcon from './assets/images/cable.inline.svg';
import PencilIcon from './assets/images/pencil.inline.svg';
import TabletIcon from './assets/images/tablet.inline.svg';
import ShieldIcon from './assets/images/shield.inline.svg';
import GlobeIcon from './assets/images/globe.inline.svg'
// import CtaImg from './assets/images/phone.inline.svg'

import './styles.scss'

const PluginsIdeal: React.FC = () => {
  return (
    <section className="container my-layout-4">
      <h2 className="text-primary my-layout-2"><FormattedMessage id="plugins.ideal.title" /></h2>
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-1 pt-1">
              <GlobeIcon />
            </div>
            <div className="col-md-11">
              <h3 className="text-primary"><FormattedMessage id="plugins.ideal.item1.title" /></h3>
              <p className="text-dark-gray-3" ><FormattedMessage id="plugins.ideal.item1.description" /></p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-1 pt-1">
              <CableIcon />
            </div>
            <div className="col-md-11">
              <h3 className="text-primary"><FormattedMessage id="plugins.ideal.item2.title" /></h3>
              <p className="text-dark-gray-3" ><FormattedMessage id="plugins.ideal.item2.description" /></p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-1 pt-1">
              {/* <TabletIcon /> */}
              <PencilIcon />
            </div>
            <div className="col-md-11">
              <h3 className="text-primary"><FormattedMessage id="plugins.ideal.item3.title" /></h3>
              <p className="text-dark-gray-3" ><FormattedMessage id="plugins.ideal.item3.description" /></p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-1 pt-1">
              <ShieldIcon />
            </div>
            <div className="col-md-11">
              <h3 className="text-primary"><FormattedMessage id="plugins.ideal.item4.title" /></h3>
              <p className="text-dark-gray-3" ><FormattedMessage id="plugins.ideal.item4.description" /></p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          {/* <CtaImg className="plugins-cta_img" /> */}
          <img src="https://kushkipagos.com/static/278d7e4b3a85b24517e746d9b65cf666/c1e9f/cajitaresponsive.png"
          className="plugins-cta_img" alt="" />
        </div>
      </div>
    </section>
  )
}

export default PluginsIdeal